import { ApiCatalog } from 'src/shared/api/ApiCatalog';
import { ProductType } from 'src/shared/api/catalog';
import TokenService from 'src/shared/api/token/TokenService';

const getBasket = (userId: string) => {
  const sessionId = TokenService.getSessionToken() || '';

  return ApiCatalog.get(`/api/Catalog/Basket/State?userId=${userId}&sessionId=${sessionId}`);
};

const getProductsList = (products: ProductType[], isSend: boolean) =>
  products.map(product => ({
    goodCode: product.id,
    groupId: product.groupId,
    price: product.newPrice,
    discount: product.discount,
    image: product.image,
    amount: product.amount,
    oldPrice: product.oldPrice,
    goodName: product.name,
    shopApiId: product?.shop?.id || 1,
    removed: product?.removed || false,
    send: isSend,
  }));

const addProductToBasket = (userId: string, products: ProductType[]) => {
  const resBasket = getProductsList(products, false);
  const sessionId = TokenService.getSessionToken() || '';

  return ApiCatalog.post(
    `/api/Catalog/Basket/State?userId=${userId}&sessionId=${sessionId}`,
    resBasket,
  );
};

const clearBasket = (userId: string) => {
  const sessionId = TokenService.getSessionToken() || '';

  return ApiCatalog.post(
    `/api/Catalog/Basket/Clear?userId=${userId}&sessionId=${sessionId}&shopId=1`,
  );
};

const sendBasket = (products: ProductType[]) => {
  const resBasket = getProductsList(products, true);

  return ApiCatalog.post(`/Catalog/Basket/State/?chatId=${1}&queryId=${1}&shopId=1`, resBasket);
};

const BasketService = {
  clearBasket,
  getBasket,
  addProductToBasket,
  sendBasket,
};

export default BasketService;
