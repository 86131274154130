import { FC } from 'react';
import cn from 'classnames';

interface ImgProps {
  src: string;
  classNames?: string;
  alt?: string;
  noBg?: boolean;
}

export const Img: FC<ImgProps> = ({ classNames, src, noBg, alt = '' }) => {
  return (
    <img
      onError={e => {
        (e.target as HTMLImageElement).src =
          'https://jmiltd.com/wp-content/uploads/2016/08/ef3-placeholder-image.jpg';
      }}
      src={src}
      alt={alt}
      className={cn({ 'bg-grey-800': !Boolean(noBg) }, 'h-full w-full object-contain', classNames)}
    />
  );
};
