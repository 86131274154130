import { FC } from 'react';
import cn from 'classnames';
import { SberSpasibo } from 'src/entity/SberSpasibo';
import { SberS } from 'src/shared/ui';

interface SberSpasiboBlockProps {
  classNames?: string;
}

export const SberSpasiboBlock: FC<SberSpasiboBlockProps> = ({ classNames }) => {
  return (
    <div className={cn('bg-grey-800 w-full rounded-xl p-2', classNames)}>
      <div className="w-full rounded-lg bg-white p-2.5">
        <div className="mb-3 flex items-center justify-between gap-2">
          <div className="font-megasansj-400 text-sm">кэшбек за оплату сбером</div>
          <SberSpasibo price={183} discount={10} />
        </div>
        <div className="flex items-center justify-between gap-2">
          <div className="font-megasansj-400 text-sm">оплата другими способами</div>
          <SberSpasibo price={183} discount={10} variant="grey" />
        </div>
      </div>
      <div className="font-megasansj-500 mt-3 flex items-center px-2">
        183
        <SberS classNames="mx-1" variant="green" size="xl" />
        по СберПрайм включены
      </div>
    </div>
  );
};
