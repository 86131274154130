import { FC } from 'react';
import cn from 'classnames';

interface SberSProps {
  variant?: 'green' | 'white';
  size?: 'base' | 'xl';
  classNames?: string;
}

export const SberS: FC<SberSProps> = ({ variant = 'white', size = 'base', classNames }) => {
  return (
    <div
      className={cn(
        {
          'bg-green-100 text-white': variant === 'green',
          'bg-white text-green-100': variant === 'white',
          'px-0.5 pt-[1px] text-ultra-xs': size === 'base',
          'px-1 pt-0.5 text-extra-xs': size === 'xl',
        },
        'font-megasansj-400 rounded-t-full rounded-ee-full',
        classNames,
      )}
    >
      C
    </div>
  );
};
