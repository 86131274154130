import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container } from 'src/entity/Container';
import { ProductAddButton } from 'src/entity/ProductAddButton';
import { SberSpasibo } from 'src/entity/SberSpasibo';
import { SberSpasiboBlock } from 'src/feature/SberSpasiboBlock';
import BasketService from 'src/shared/api/basket/BasketService';
import { ProductType } from 'src/shared/api/catalog';
import PaymentService from 'src/shared/api/payment/PaymentService';
import { ReactComponent as TrashIcon } from 'src/shared/assets/icons/kit/trash.svg';
import img from 'src/shared/assets/images/cart-empty.webp';
import { routesEnum } from 'src/shared/const';
import { addSpaces, declOfNum } from 'src/shared/lib/utils';
import { BasketContext, UserContext } from 'src/shared/store';
import { Button, CheckBox, Img, WhiteBox } from 'src/shared/ui';
import { Loader } from 'src/shared/ui/Loader';

export const BasketPage: FC = () => {
  const { user } = useContext(UserContext);
  const { basket, setBasket, isBasketLoading } = useContext(BasketContext);
  const navigate = useNavigate();

  const [selected, setSelected] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (basket.length) {
      setSelected(basket.map(({ id }) => id));
    }
  }, [basket]);

  const handleGetToken = async () => {
    if (isLoading || !user) {
      return;
    }

    setIsLoading(true);

    try {
      const productsToRemove = basket
        .filter(el => !selected.includes(el.id))
        .map(el => ({
          ...el,
          amount: 0,
          removed: true,
        }));

      if (productsToRemove.length) {
        await BasketService.addProductToBasket(user.id, productsToRemove);
      }

      const { data } = await PaymentService.getConfirmationToken({
        email: user.email,
        userId: user.id,
      });
      navigate(`/payment/${data.confirmation_token}`);
    } catch (e) {
    } finally {
      setIsLoading(false);
      setBasket(prev => prev.filter(el => selected.includes(el.id)));
    }
  };

  const summ = useMemo(() => {
    const res = { count: 0, price: 0 };

    if (!basket.length) {
      return res;
    }

    basket.forEach(el => {
      if (!selected.length) {
        res.price += el.amount * +(el.newPrice || 0);
        res.count += el.amount;
      }

      if (selected.length && selected.includes(el.id)) {
        res.price += el.amount * +(el.newPrice || 0);
        res.count += el.amount;
      }
    });

    return res;
  }, [basket, selected]);

  const selectAll = () => {
    setSelected(prev => {
      if (prev.length === basket.length) {
        return [];
      }

      return basket.map(el => el.id);
    });
  };

  const removeFromBasket = async (product: ProductType) => {
    if (!user?.id) {
      return;
    }

    try {
      await BasketService.addProductToBasket(user.id, [{ ...product, amount: 0, removed: true }]);

      setBasket(prev => prev.filter(el => el.id !== product.id));
    } catch (e) {}
  };

  return (
    <Container title="Корзина">
      {isBasketLoading ? (
        <div className="flex flex-1 items-center justify-center">
          <Loader stroke="#8654cc" classNames="w-32 h-32" />
        </div>
      ) : null}

      {!basket.length && !isBasketLoading ? (
        <div className="mx-auto flex w-fit flex-col items-center py-8">
          <div className="mb-8 h-56 w-56">
            <Img src={img} noBg />
          </div>

          <div className="font-megasansj-400 mb-4 text-black-100">В корзине пока пусто.</div>

          {!user && (
            <Button size="lg" variant="grey" onClick={() => navigate(routesEnum.LOGIN)}>
              Войти
            </Button>
          )}

          {user && !basket.length ? (
            <Button size="lg" variant="grey" onClick={() => navigate(routesEnum.CATALOG)}>
              В каталог
            </Button>
          ) : null}
        </div>
      ) : null}

      {!isBasketLoading && basket.length ? (
        <>
          <WhiteBox classNames="flex w-full flex-col d-xs:flex-row">
            <div className="flex-1">
              <div className="flex cursor-pointer items-center pb-6" onClick={selectAll}>
                <CheckBox isActive={selected.length === basket.length} />
                <div className="font-megasansj-400 ml-2 text-sm text-black">Выбрать все</div>
              </div>

              {basket.map(product => (
                <div key={`basket-${product.id}`} className="flex border-t py-8">
                  <Link
                    to={`/product/${product.id}`}
                    className="relative mr-8 h-28 w-28 flex-shrink-0"
                  >
                    <CheckBox
                      isActive={selected.includes(product.id)}
                      onClick={e => {
                        e?.preventDefault();
                        setSelected(prev =>
                          prev.includes(product.id)
                            ? prev.filter(el => el !== product.id)
                            : [...prev, product.id],
                        );
                      }}
                      classNames="absolute top-0 left-0"
                    />
                    <Img src={product.image} />
                  </Link>

                  <div className="font-megasansj-400 mr-8 flex-1 text-sm text-black-100">
                    {product.name}
                  </div>

                  <div className="flex max-w-80 flex-1 items-center">
                    <div className="mr-10 flex flex-col items-center">
                      <ProductAddButton product={product} />
                      <div className="font-megasansj-400 mt-2 text-xs text-grey-500">{`${product.newPrice} ₽/шт.`}</div>
                    </div>

                    <div>
                      <SberSpasibo price={230} classNames="mb-1" />
                      <div className="font-megasansj-500 mr-8 whitespace-nowrap text-xl text-black-100">{`${+product.newPrice.replace(',', '.') * product.amount} ₽`}</div>
                    </div>

                    <button className="ml-auto p-2" onClick={() => removeFromBasket(product)}>
                      <TrashIcon className="h-5 w-5 fill-grey-500" />
                    </button>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex items-center justify-between d-xs:hidden">
              <div>
                <div className="font-megasansj-400 text-sm text-grey-500">{`${summ.count} ${declOfNum(summ.count, ['товар', 'товара', 'товаров'])}`}</div>
                <div className="font-megasansj-500 ml-auto text-xl">{`${addSpaces(summ.price)} ₽`}</div>
              </div>
              <div>
                <SberSpasiboBlock classNames="mb-4" />{' '}
                <Button
                  classNames="w-full"
                  variant="violet"
                  disabled={isLoading}
                  onClick={handleGetToken}
                >
                  {isLoading ? <Loader classNames="w-5 h-5" /> : 'Оформить заказ'}
                </Button>
              </div>
            </div>

            <div className="hidden w-80 flex-shrink-0 pl-8 text-black-100 d-xs:block">
              <div className="font-megasansj-400 mb-8 flex text-sm text-grey-900">
                <div>{`${summ.count} ${declOfNum(summ.count, ['товар', 'товара', 'товаров'])}`}</div>
                <div className="ml-auto">{`${addSpaces(summ.price)} ₽`}</div>
              </div>

              <SberSpasiboBlock classNames="mb-8" />

              <div className="font-megasansj-400 mb-8 flex items-center text-xl">
                <div>Итого:</div>
                <div className="ml-auto ">{`${addSpaces(summ.price)} ₽`}</div>
              </div>

              <Button
                classNames="w-full"
                variant={!selected.length ? 'disabled' : 'violet'}
                size="lg"
                disabled={isLoading || !selected.length}
                onClick={handleGetToken}
              >
                {isLoading ? <Loader classNames="w-5 h-5" /> : 'Оформить заказ'}
              </Button>
            </div>
          </WhiteBox>
        </>
      ) : null}
    </Container>
  );
};
