import { useContext, useMemo, useState } from 'react';
import BasketService from 'src/shared/api/basket/BasketService';
import { ProductType } from 'src/shared/api/catalog';
import { BasketContext, UserContext } from 'src/shared/store';

export const useAddToBasket = (product: ProductType) => {
  const { user } = useContext(UserContext);
  const { basket, setBasket } = useContext(BasketContext);

  const [isLoading, setIsLoading] = useState(false);

  const amount = useMemo(() => {
    if (!basket.length) {
      return 0;
    }

    const productInBasket = basket.find(el => el.id === product.id);

    return productInBasket?.amount || 0;
  }, [basket, product]);

  const addToBasket = async () => {
    if (isLoading || !user) {
      return;
    }
    setIsLoading(true);
    try {
      await BasketService.addProductToBasket(user.id, [{ ...product, amount: amount + 1 }]);

      setBasket(prev => {
        if (amount === 0) {
          return [...prev, { ...product, amount: 1 }];
        }
        return prev.map(el => {
          if (el.id === product.id) {
            return { ...el, amount: el.amount + 1 };
          }
          return el;
        });
      });
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const removeOneFromBasket = async () => {
    if (isLoading || !user) {
      return;
    }
    setIsLoading(true);
    try {
      await BasketService.addProductToBasket(user.id, [
        {
          ...product,
          amount: amount - 1,
          ...(amount === 1 && { removed: true }),
        },
      ]);

      setBasket(prev => {
        if (amount === 1) {
          return prev.filter(el => el.id !== product.id);
        }

        return prev.map(el => {
          if (el.id === product.id) {
            return { ...el, amount: el.amount - 1 };
          }
          return el;
        });
      });
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const removeFromBasket = async () => {
    if (isLoading || !user) {
      return;
    }

    setIsLoading(true);
    try {
      await BasketService.addProductToBasket(user.id, [{ ...product, amount: 0 }]);

      setBasket(prev => prev.filter(el => el.id !== product.id));
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  return { removeOneFromBasket, removeFromBasket, addToBasket, isLoading, amount };
};
