import { FC, useState } from 'react';
import cn from 'classnames';
import { Img } from 'src/shared/ui';

interface CardImgCarouselProps {
  images: string[];
}

export const CardImgCarousel: FC<CardImgCarouselProps> = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);

  const imagesList = images.slice(0, 4);

  return (
    <div className="group relative h-full w-full overflow-hidden rounded-lg">
      {imagesList.map((img, i) => (
        <div
          key={`img-${i}`}
          className={cn({ hidden: i !== currentImage }, 'absolute inset-0 h-full w-full')}
        >
          <Img src={img} />
        </div>
      ))}

      <div className="z-1 absolute inset-0 flex h-full w-full flex-nowrap">
        {imagesList.map((_, i) => (
          <div
            key={`img-selector-${i}`}
            onMouseEnter={() => setCurrentImage(i)}
            onMouseLeave={() => setCurrentImage(0)}
            className={cn(
              {
                'group-hover:border-grey-400': i !== currentImage,
                'group-hover:border-black-100': i === currentImage,
              },
              'h-full w-full border-b-2 border-transparent',
            )}
          />
        ))}
      </div>
    </div>
  );
};
