import { useContext, useEffect, useState } from 'react';
import { ProductType } from 'src/shared/api/catalog';
import CatalogService from 'src/shared/api/catalog/CatalogService';
import { CatalogContext, UserContext } from 'src/shared/store';
import { SortType } from 'src/shared/types';

export const useProducts = (selectedSort: SortType, isBought?: boolean, isFavorite?: boolean) => {
  const { filters, isLoadingInitialFilters } = useContext(CatalogContext);
  const { user } = useContext(UserContext);

  const [products, setProducts] = useState<ProductType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoadingInitialFilters) {
      setIsLoading(true);
    }
  }, [isLoadingInitialFilters]);

  useEffect(() => {
    if (!filters.selectedGroup || !user || isLoadingInitialFilters) {
      return;
    }

    (async () => {
      try {
        setIsLoading(true);

        const { data } = isFavorite
          ? await CatalogService.getFavoriteProducts(selectedSort.value, filters, user.id, isBought)
          : await CatalogService.getProducts(selectedSort.value, filters, user.id);

        setProducts(data);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [filters, isLoadingInitialFilters, selectedSort, isBought]);

  return { isLoading, products, setProducts };
};
