import { FC } from 'react';
import cn from 'classnames';
import { SberS } from 'src/shared/ui';

interface SberSpasiboProps {
  discount?: number;
  price: number;
  variant?: 'grey' | 'green';
  classNames?: string;
}

export const SberSpasibo: FC<SberSpasiboProps> = ({
  discount,
  price,
  variant = 'green',
  classNames,
}) => {
  return (
    <div
      className={cn(
        {
          'bg-green-100': variant === 'green',
          'bg-grey-900': variant === 'grey',
        },
        'flex h-4 w-fit items-center rounded-full px-1',
        classNames,
      )}
    >
      {discount && (
        <div className="mr-1 flex items-center rounded-full bg-white px-1">
          <div className="font-megasansj-400 mt-[1px] text-ultra-xs">{`${discount}%`}</div>
        </div>
      )}
      <div className="font-megasansj-400 mr-1 mt-[1px] text-extra-xs text-white">{price}</div>
      <SberS />
    </div>
  );
};
