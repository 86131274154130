import { initialInterval } from 'src/shared/const';
import { ICatalogContext } from 'src/shared/store/catalog/types';

export const catalogContextDefaultState: ICatalogContext = {
  initialFilters: null,
  setInitialFilters: () => {},
  isLoadingInitialFilters: true,
  setIsLoadingInitialFilters: () => {},

  filters: { selectedGroup: null, search: '', parameters: {}, price: initialInterval },
  setFilters: () => {},

  resetFilters: () => {},
};
