import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from 'src/app/providers/UserProvider';
import { Container } from 'src/entity/Container';
import { ProfileNavigation } from 'src/feature/ProfileNavigation';
import { ProductType } from 'src/shared/api/catalog';
import PaymentService from 'src/shared/api/payment/PaymentService';
import img from 'src/shared/assets/images/favorites-empty.svg';
import { routesEnum } from 'src/shared/const';
import { OrderType } from 'src/shared/types';
import { Button, Img } from 'src/shared/ui';
import { Loader } from 'src/shared/ui/Loader';

const months = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

export const OrdersPage: FC = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const [orders, setOrders] = useState<OrderType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tab, setTab] = useState<'all' | 'active' | 'hidden'>('all');

  const tabs: ('all' | 'active' | 'hidden')[] = ['all', 'active', 'hidden'];

  const ruTabs = {
    all: 'Все',
    active: 'Активные',
    hidden: 'Скрытые',
  };

  const filteredList = useMemo(() => {
    if (tab === 'all') {
      return orders;
    }

    return orders.filter(el => (tab === 'hidden' ? !!el.hidden : !el.hidden));
  }, [orders, tab]);

  useEffect(() => {
    if (!user) {
      setIsLoading(true);
      return;
    }

    (async () => {
      try {
        const { data } = await PaymentService.getOrders(user.id);

        setOrders(data);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [user]);

  const calcPrice = (products: ProductType[]) => {
    return products.reduce((summ, curr) => {
      return summ + curr.amount * +(curr.newPrice.replace(',', '.') || 0);
    }, 0);
  };

  const handleVisibility = async (order: OrderType) => {
    try {
      await PaymentService.setOrderVisibility(order.id, !order.hidden);
      setOrders(prev => prev.map(el => (el.id === order.id ? { ...el, hidden: !el.hidden } : el)));
    } catch (e) {}
  };

  const getDate = (date: string) => {
    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth();
    return `${day} ${months[month]}`;
  };

  return (
    <Container>
      <div className="flex flex-1">
        <div className="hidden h-full w-72 flex-shrink-0 d-xs:block">
          <ProfileNavigation />
        </div>

        <div className="flex flex-1 flex-col d-xs:ml-5">
          <div className="font-megasansj-400 mb-10 text-3xl">Заказы</div>

          <div className="mb-5 flex gap-3">
            <Button classNames="rounded-lg" variant="violet" size="sm">
              Список заказов
            </Button>
            <Button
              classNames="rounded-lg"
              variant="greyBlack"
              size="sm"
              onClick={() => navigate(`${routesEnum.FAVORITES}?bought`)}
            >
              Купленные товары
            </Button>
          </div>

          <div className="mb-5 flex gap-3">
            {tabs.map(el => (
              <Button
                classNames="rounded-lg"
                variant={el === tab ? 'violet' : 'greyBlack'}
                key={el}
                size="sm"
                onClick={() => setTab(el)}
              >
                {ruTabs[el]}
              </Button>
            ))}
          </div>

          {isLoading ? <Loader classNames="mt-32 w-32 h-20 mx-auto" stroke="#8654cc" /> : null}

          {!isLoading && !filteredList?.length ? (
            <div className="mx-auto flex w-fit flex-col items-center py-8">
              <div className="mb-8 h-24 w-48">
                <Img src={img} noBg />
              </div>

              <div className="font-megasansj-400 mb-10 text-black-100">Здесь пока пусто</div>

              <Button
                onClick={() => navigate(routesEnum.CATALOG)}
                variant="violet"
                size="lg"
                classNames="w-52"
              >
                В каталог
              </Button>
            </div>
          ) : null}

          {!isLoading && filteredList?.length ? (
            <>
              {filteredList.map(order => (
                <div
                  className="mb-8 w-full rounded-xl border border-grey-400 p-4 d-xs:p-6"
                  key={order.id}
                >
                  <div className="mb-2 flex flex-col d-xs:mb-8 d-xs:flex-row">
                    <div className="font-megasansj-500 text-xl d-xs:text-2xl">{`Заказ от ${getDate(order.dateTime)}`}</div>
                    <div
                      className={'font-megasansj-400 d-xs:ml-auto d-xs:text-2xl'}
                    >{`${calcPrice(order.orderItems)} ₽`}</div>
                  </div>

                  <div className="mb-8 flex flex-col gap-3 d-xs:flex-row d-xs:items-center">
                    <div>
                      <div
                        className={
                          'font-megasansj-400 flex h-8 items-center rounded-full bg-gradient-100 ' +
                          'mb-3 w-fit px-4 text-violet-100'
                        }
                      >
                        {order.status}
                      </div>

                      <div className="font-megasansj-400">
                        {`Доставлен на почту  ${getDate(order.dateTime)}`}
                      </div>
                    </div>

                    <div className="flex gap-5 d-xs:ml-auto">
                      {order.orderItems.map(product => (
                        <Link
                          to={`/product/${product.goodId}`}
                          key={`order-${order.id}-${product.id}`}
                          className="h-20 w-20"
                        >
                          <Img src={product.image} />
                        </Link>
                      ))}
                    </div>
                  </div>

                  <Button
                    size="sm"
                    classNames="ml-auto"
                    variant="violetText"
                    onClick={() => handleVisibility(order)}
                  >
                    {order.hidden ? 'Убрать из скрытых' : 'Скрыть заказ'}
                  </Button>
                </div>
              ))}
            </>
          ) : null}
        </div>
      </div>
    </Container>
  );
};
