import { ApiCatalog } from 'src/shared/api/ApiCatalog';
import { FiltersType } from 'src/shared/api/catalog/types';

const getFiltersStr = (sort: string, filters: FiltersType) => {
  const { price, search, parameters } = filters;
  const min = Math.trunc(price.min);
  const max = Math.ceil(price.max);

  const sortStr = `sort=${sort}`;
  const priceStr = `priceMin=${min}&priceMax=${max}`;
  const searchStr = `filter=${search}`;
  const groupStr = `groupIds=${filters.selectedGroup?.id}`;

  const paramsArr: string[] = [];

  Object.keys(parameters).forEach(key => {
    if (parameters[key].length) {
      paramsArr.push(`${key}:${parameters[key].join('|')}`);
    }
  });

  const parametersStr = `parameters=${paramsArr.join(',')}`;

  return `${sortStr}&${priceStr}&${searchStr}&${groupStr}&${parametersStr}`;
};

const getGroups = () => ApiCatalog.get(`/api/Catalog/Groups`);

const getFilters = (userId: string, groupIds: string, page: 'catalog' | 'favorite' | 'bought') =>
  ApiCatalog.get(`/api/Catalog/Filters?userId=${userId}&groupIds=${groupIds}&page=${page}`);

const getBanners = () => ApiCatalog.get(`/api/Catalog/BannersList`);

const getProducts = (sort: string, filters: FiltersType, userId: string) => {
  const str = getFiltersStr(sort, filters);

  return ApiCatalog.get(`/api/Catalog/Groups/Goods?userId=${userId}&${str}`);
};

const getProduct = (id: string) => ApiCatalog.get(`/api/Catalog/Groups/Good?goodId=${id}`);

const getSimilarProducts = (id: string) => ApiCatalog.get(`/api/Catalog/SimilarGoods?goodId=${id}`);

const setFavorite = (id: number) => ApiCatalog.post(`/api/Catalog/Favorite/Goods/${id}`);

const deleteFavorite = (id: number) => ApiCatalog.delete(`/api/Catalog/Favorite/Goods/${id}`);

const getFavoriteProducts = (
  sort: string,
  filters: FiltersType,
  userId: string,
  isBought?: boolean,
) => {
  const str = getFiltersStr(sort, filters);
  const boughtStr = isBought ? `&bought=${true}` : '';

  return ApiCatalog.get(`/api/Catalog/Favorite/Goods?userId=${userId}&${str}${boughtStr}`);
};

const CatalogService = {
  getGroups,
  getProducts,
  getProduct,
  getSimilarProducts,
  getFilters,
  getBanners,
  getFavoriteProducts,
  setFavorite,
  deleteFavorite,
};

export default CatalogService;
