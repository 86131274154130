import { FC } from 'react';
import { Link } from 'react-router-dom';
import { FavoriteButton } from 'src/entity/FavoriteButton';
import { ProductAddButton } from 'src/entity/ProductAddButton';
import { ProductType } from 'src/shared/api/catalog';
import { Img, Stars } from 'src/shared/ui';

interface ProductListCardProps {
  data: ProductType;
  onFavorite: (data: ProductType) => void;
}

export const ProductListCard: FC<ProductListCardProps> = ({ data, onFavorite }) => {
  const { name, id, carouselImages, newPrice, commentsCount, rating } = data;

  return (
    <div className="flex w-full gap-6 rounded-2xl p-2 hover:shadow-100">
      <Link
        to={`/product/${id}`}
        className="relative aspect-square h-64 flex-shrink-0 overflow-hidden rounded-lg"
      >
        <FavoriteButton onClick={onFavorite} data={data} classNames="absolute top-4 right-4 z-10" />

        <Img src={carouselImages[0]} />
      </Link>

      <div className="flex flex-1 flex-col gap-2">
        <Link className="font-megasansj-400 line-clamp-2 truncate text-sm" to={`/product/${id}`}>
          {name}
        </Link>

        <div className="flex items-center">
          <Stars rate={rating} />
          <div className="font-megasansj-400 ml-2 mt-1 text-xs">{commentsCount}</div>
        </div>
      </div>

      <div className="w-72 flex-shrink-0">
        <div className="font-megasansj-400">{`${newPrice} ₽`}</div>
        <ProductAddButton product={data} />
      </div>
    </div>
  );
};
