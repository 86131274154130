import { FC } from 'react';
import { Link } from 'react-router-dom';
import { FavoriteButton } from 'src/entity/FavoriteButton';
import { ProductAddButton } from 'src/entity/ProductAddButton';
import { SberSpasibo } from 'src/entity/SberSpasibo';
import { ProductType } from 'src/shared/api/catalog';
import { CardImgCarousel, Stars } from 'src/shared/ui';

interface ProductGridCardProps {
  data: ProductType;
  onFavorite: (data: ProductType) => void;
}

export const ProductGridCard: FC<ProductGridCardProps> = ({ data, onFavorite }) => {
  const { name, id, newPrice, carouselImages, rating, commentsCount, image, parameters } = data;
  const owner = parameters.find(el => el.parameterName === 'Разработчик');

  return (
    <div
      className={
        'flex min-h-36 w-1/2 max-w-80 flex-col border-b-2 border-grey-300 px-3 py-4 ' +
        'd-xs:max-w-auto d-xs:mb-6 d-xs:w-[17rem] d-xs:rounded-2xl d-xs:border-transparent ' +
        'relative d-xs:p-2.5 d-xs:hover:shadow-100'
      }
    >
      <FavoriteButton onClick={onFavorite} data={data} classNames="absolute top-5 right-5 z-10" />

      <Link
        to={`/product/${id}`}
        className="mb-2.5 aspect-square w-full flex-shrink-0 overflow-hidden rounded-lg"
      >
        <CardImgCarousel images={carouselImages?.length ? carouselImages : [image || '']} />
      </Link>

      <div className="flex flex-1 flex-col justify-between">
        <div className="pt-5">
          <div className="flex items-center">
            <div className="font-megasansj-400 mr-2">{`${newPrice} ₽`}</div>
            <SberSpasibo discount={10} price={200} />
          </div>
          <Link
            to={`/product/${id}`}
            className="font-megasansj-400 mb-2 line-clamp-4 truncate text-xs"
          >
            {name}
          </Link>

          <div className="mb-2 flex items-center">
            <Stars rate={rating} />
            <div className="font-megasansj-400 ml-2 mt-1 text-xs">{commentsCount}</div>
          </div>

          {owner?.parameterValues?.[0]?.valueName && (
            <div className="font-megasansj-400 mb-2 text-xs text-grey-500">{`Разработчик: ${owner.parameterValues[0].valueName}`}</div>
          )}

          <ProductAddButton product={data} />
        </div>
      </div>
    </div>
  );
};
