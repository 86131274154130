import React, { createContext, FC, ReactNode, useState } from 'react';
import { FiltersType, InitialFiltersType } from 'src/shared/api/catalog/types';
import { catalogContextDefaultState } from 'src/shared/store/catalog/constants';
import { ICatalogContext } from 'src/shared/store/catalog/types';

export const CatalogContext = createContext<ICatalogContext>(catalogContextDefaultState);

interface ICatalogProviderProps {
  children: ReactNode;
}

export const CatalogProvider: FC<ICatalogProviderProps> = ({ children }) => {
  const [initialFilters, setInitialFilters] = useState<InitialFiltersType | null>(null);
  const [isLoadingInitialFilters, setIsLoadingInitialFilters] = useState<boolean>(true);

  const [filters, setFilters] = useState<FiltersType>(catalogContextDefaultState.filters);

  const resetFilters = () => {
    setFilters(prev => ({
      ...prev,
      price: {
        min: initialFilters ? +(initialFilters?.priceMin || 0) : 0,
        max: initialFilters ? +(initialFilters?.priceMax || 0) : 0,
      },
      parameters: {},
    }));
  };

  return (
    <CatalogContext.Provider
      value={{
        initialFilters,
        setInitialFilters,
        isLoadingInitialFilters,
        setIsLoadingInitialFilters,

        filters,
        setFilters,

        resetFilters,
      }}
    >
      {children}
    </CatalogContext.Provider>
  );
};
