import { useContext, useEffect } from 'react';
import { CatalogContext } from 'src/app/providers/CatalogProvider';
import { GroupContext } from 'src/app/providers/GroupProvider';
import { UserContext } from 'src/app/providers/UserProvider';
import CatalogService from 'src/shared/api/catalog/CatalogService';

export const useFilters = (isFavorite?: boolean, isBought?: boolean) => {
  const { setIsLoadingInitialFilters, setInitialFilters, setFilters } = useContext(CatalogContext);
  const { setGroups, setIsGroupsLoading } = useContext(GroupContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      try {
        if (!user) {
          return;
        }

        setIsLoadingInitialFilters(true);
        setIsGroupsLoading(true);

        const groups = await CatalogService.getGroups();

        const page = isBought ? 'bought' : isFavorite ? 'favorite' : 'catalog';

        const filtersRes = await CatalogService.getFilters(user.id, groups.data?.[0].id, page);

        setGroups(groups.data);
        setInitialFilters({
          ...filtersRes.data,
          priceMax: +(filtersRes.data.priceMax.replace(',', '.') || 0),
          priceMin: +(filtersRes.data.priceMin.replace(',', '.') || 0),
        });

        setFilters(prev => ({
          ...prev,
          selectedGroup: groups.data?.[0] || null,
          price: {
            min: +(filtersRes.data.priceMin.replace(',', '.') || 0),
            max: +(filtersRes.data.priceMax.replace(',', '.') || 0),
          },
        }));
      } catch (e) {
      } finally {
        setIsLoadingInitialFilters(false);
        setIsGroupsLoading(false);
      }
    })();
  }, [isFavorite, user, isBought]);
};
